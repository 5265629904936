<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16"></div>
      <el-tabs
        v-model="activeName"
        type="border-card"
        @tab-click="getInventorysList"
        :before-leave="name => (['3'].includes(name) ? Promise.reject() : true)"
      >
        <el-tab-pane label="部件库存" name="1" key="1">
          <DynamicUTable
            ref="partMultiTable"
            :tableData="tableData"
            :columns="partTableProperties"
            v-loading="loadFlag"
            :totalPage="totalPage"
            :need-fixed-height="true"
            :need-search="true"
            :need-check-box="false"
            @select="selectRows"
            @selection-change="handleSelectionChange"
            @select-all="selectRows"
            @getTableData="getInventorysList"
            :row-click="row => (partCurrentRow = row)"
            :computed-data="{ available_num: available_num, inventory_money: inventory_money }"
          >
            <template v-slot:operation="scope">
              <el-link
                type="primary"
                v-if="scope.row.status === 0"
                class="vg_cursor_hander vg_mr_8"
                @click="plusNum(scope.row, scope.$index)"
                >增加
              </el-link>
              <el-link
                type="primary"
                v-if="scope.row.status === 0"
                class="vg_cursor_hander vg_mr_8"
                @click="minusNum(scope.row, scope.$index)"
                >减少</el-link
              >
              <el-link type="danger" class="vg_cursor_hander vg_mr_8" @click="cancellation(scope.row, scope.$index)">作废</el-link>
              <el-button type="primary" size="mini" @click="saveRow(scope.row, scope.$index)">保存</el-button>
            </template>
            <template v-slot:default-operation="scope">
              <el-link
                type="primary"
                v-if="scope.row.status === 0"
                class="vg_cursor_hander vg_mr_8"
                @click="plusNum(scope.row, scope.$index)"
                >增加</el-link
              >
              <el-link
                type="primary"
                v-if="scope.row.status === 0"
                class="vg_cursor_hander vg_mr_8"
                @click="minusNum(scope.row, scope.$index)"
                >减少</el-link
              >
              <el-link type="danger" class="vg_cursor_hander vg_mr_8" @click="cancellation(scope.row, scope.$index)">作废</el-link>
              <el-button type="primary" size="mini" @click="saveRow(scope.row, scope.$index)">保存</el-button>
            </template>
            <template v-slot:recording="scope">
              <el-link type="primary" class="vg_cursor_hander" @click="openRecording">详情</el-link>
            </template>
            <template v-slot:default-recording="scope">
              <el-link type="primary" class="vg_cursor_hander" @click="openRecording">详情</el-link>
            </template>
            <template v-slot:status="scope">
              <el-tag type="success" size="small" v-if="scope.row.status === 0">有效</el-tag>
              <el-tag type="danger" size="small" v-if="scope.row.status === 1">作废</el-tag>
            </template>
            <template v-slot:default-status="scope">
              <el-tag type="success" size="small" v-if="scope.row.status === 0">有效</el-tag>
              <el-tag type="danger" size="small" v-if="scope.row.status === 1">作废</el-tag>
            </template>
          </DynamicUTable>
        </el-tab-pane>
        <el-tab-pane label="材料库存" name="2" key="2">
          <DynamicUTable
            ref="mtrbMultiTable"
            :tableData="tableData"
            :columns="mtrbTableProperties"
            v-loading="loadFlag"
            :totalPage="totalPage"
            :need-fixed-height="true"
            :need-search="true"
            :need-check-box="false"
            @select="selectRows"
            @selection-change="handleSelectionChange"
            @select-all="selectRows"
            @getTableData="getInventorysList"
            :row-click="row => (mtrbCurrentRow = row)"
            :computed-data="{ available_num: available_num2, inventory_money: inventory_money2 }"
          >
            <template v-slot:operation="scope">
              <el-link type="primary" class="vg_cursor_hander vg_mr_8" @click="plusNum(scope.row, scope.$index)">增加</el-link>
              <el-link type="primary" class="vg_cursor_hander vg_mr_8" @click="minusNum(scope.row, scope.$index)">减少</el-link>
              <el-link type="danger" class="vg_cursor_hander vg_mr_8" @click="cancellation(scope.row, scope.$index)">作废</el-link>
              <el-button type="primary" size="mini" @click="saveRow(scope.row, scope.$index)">保存</el-button>
            </template>
            <template v-slot:default-operation="scope">
              <el-link type="primary" class="vg_cursor_hander vg_mr_8" @click="plusNum(scope.row, scope.$index)">增加</el-link>
              <el-link type="primary" class="vg_cursor_hander vg_mr_8" @click="minusNum(scope.row, scope.$index)">减少</el-link>
              <el-link type="danger" class="vg_cursor_hander vg_mr_8" @click="cancellation(scope.row, scope.$index)">作废</el-link>
              <el-button type="primary" size="mini" @click="saveRow(scope.row, scope.$index)">保存</el-button>
            </template>
            <template v-slot:recording="scope">
              <el-link type="primary" class="vg_cursor_hander" @click="openRecording">详情</el-link>
            </template>
            <template v-slot:default-recording="scope">
              <el-link type="primary" class="vg_cursor_hander" @click="openRecording">详情</el-link>
            </template>
            <template v-slot:status="scope">
              <el-tag type="success" size="small" v-if="scope.row.status === 0">有效</el-tag>
              <el-tag type="danger" size="small" v-if="scope.row.status === 1">作废</el-tag>
            </template>
            <template v-slot:default-status="scope">
              <el-tag type="success" size="small" v-if="scope.row.status === 0">有效</el-tag>
              <el-tag type="danger" size="small" v-if="scope.row.status === 1">作废</el-tag>
            </template>
          </DynamicUTable>
        </el-tab-pane>
        <el-tab-pane name="3" key="3">
          <span slot="label">
            <el-button
              style="position: relative; top: -2px"
              type="info"
              size="small"
              class="vd_export"
              @click="buttonRefresh()"
              icon="el-icon-refresh-right"
              >刷新
            </el-button>
          </span>
        </el-tab-pane>
      </el-tabs>
      <el-dialog title="库存记录" :visible.sync="dialogVisible" width="50%">
        <div
          v-for="(item, index) in activeName === '1' ? partCurrentRow.recording : mtrbCurrentRow.recording"
          :key="item.recording_key + index"
        >
          <span>{{ item.recording_key }}:</span>
          <el-link
            v-if="item.type"
            type="primary"
            class="vg_cursor_hander"
            @click="
              dialogVisible = false;
              jump(activeName === '1' ? 'podr_list' : 'modr_list', {
                perm_id: activeName === '1' ? 127 : 128,
                [activeName === '1' ? 'podr_no' : 'modr_no']: item.recording_value
              });
            "
            >{{ item.recording_value }}</el-link
          >
          <span v-else>{{ item.recording_value }}</span>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { inventorysAPI } from '@api/modules/inventorys';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { mtrbTableProperties, partTableProperties } from '@/views/InventoryManagement/InventorysManage/inventorys';
import { getSupp } from '@api/public';
import { cloneDeep } from 'lodash';
import { keepNumber } from '@assets/js/regExUtil';
import { BigNumber } from 'bignumber.js';
import { getNoCatch } from '@api/request';

export default {
  name: 'InventorysList',
  components: {
    DynamicUTable
  },
  data() {
    return {
      otherForm: {
        minus: '',
        plus: ''
      },
      activeName: '1',
      partTableProperties: cloneDeep(partTableProperties),
      mtrbTableProperties: cloneDeep(mtrbTableProperties),
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      partCurrentRow: {},
      mtrbCurrentRow: {},
      dialogVisible: false
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === '/inventorys_add' || from.path === '/inventorys_edit') {
        this.initData();
      }
    }
  },
  computed: {
    available_num() {
      let { prod_num, inventory_freezenum } = this.partCurrentRow;
      let temp = new BigNumber(prod_num).minus(inventory_freezenum);
      if (!temp.isFinite()) {
        this.partCurrentRow.available_num = 0;
        return '自动计算';
      }
      this.partCurrentRow.available_num = temp.toFixed(4);
      return temp.toFixed(4);
    },
    inventory_money() {
      let { prod_price, prod_num } = this.partCurrentRow;
      let temp = new BigNumber(prod_price).times(prod_num);
      if (!temp.isFinite()) {
        this.partCurrentRow.inventory_money = 0;
        return '自动计算';
      }
      this.partCurrentRow.inventory_money = temp.toFixed(4);
      return temp.toFixed(4);
    },
    available_num2() {
      let { mtrb_num, inventory_freezenum } = this.mtrbCurrentRow;
      let temp = new BigNumber(mtrb_num).minus(inventory_freezenum);

      if (!temp.isFinite()) {
        this.mtrbCurrentRow.available_num = 0;
        return '自动计算';
      }
      this.mtrbCurrentRow.available_num = temp.toFixed(4);
      return temp.toFixed(4);
    },
    inventory_money2() {
      let { prod_mtrb_price, mtrb_num } = this.mtrbCurrentRow;
      let temp = new BigNumber(prod_mtrb_price).times(mtrb_num);
      if (!temp.isFinite()) {
        this.mtrbCurrentRow.inventory_money = 0;
        return '自动计算';
      }
      this.mtrbCurrentRow.inventory_money = temp.toFixed(4);
      return temp.toFixed(4);
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    keepNumber,
    initData() {
      this.getInventorysList();
      this.changeProperties();
    },
    async changeProperties() {
      let suppObjList = await getSupp('valueList');
      let suppPart = this.partTableProperties.find(({ prop }) => prop === 'supp_id');
      suppPart.subItem.options = suppObjList;
      suppPart.subItem.change = (val, row) => {
        row.recording_type = 4;
        this.saveRow(row);
        row.recording_type = 5;
      };
      let suppMtrb = this.mtrbTableProperties.find(({ prop }) => prop === 'supp_id');
      suppMtrb.subItem.options = suppObjList;
      suppMtrb.subItem.change = (val, row) => {
        row.recording_type = 4;
        this.saveRow(row);
        row.recording_type = 5;
      };
      setTimeout(() => {
        let tableMaxHeight = 0;
        if (this.$refs.partMultiTable) tableMaxHeight = this.$refs.partMultiTable.tableMaxHeight;
        if (this.$refs.mtrbMultiTable) this.$refs.mtrbMultiTable.tableMaxHeight = tableMaxHeight;
      });
    },
    async getInventorysList() {
      this.loadFlag = true;
      let searchForm = this.$refs[this.activeName === '1' ? 'partMultiTable' : 'mtrbMultiTable'].searchForm;
      searchForm.type = this.activeName;
      await inventorysAPI.getInventorys(searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        this.loadFlag = false;
      });
    },
    buttonRefresh() {
      this.$refs[this.activeName === '1' ? 'partMultiTable' : 'mtrbMultiTable'].resetFields();
      this.getInventorysList();
    },
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    async saveRow(row, index) {
      let { code, data } =
        this.activeName === '1' ? await inventorysAPI.editInventoryPodr(row) : await inventorysAPI.editInventoryMtrb(row);
      if (code === 0) {
        this.loadFlag = true;
        await getNoCatch(inventorysAPI[this.activeName === '1' ? 'getInventoryProdById' : 'getInventoryMtrbById'], { id: data }).then(
          ({ data: subData }) => {
            this.$set(this.tableData, index, subData);
            if (this.activeName === '1') {
              this.$nextTick(() => {
                this.$set(this.partCurrentRow, 'recording', subData.recording);
                this.$set(this.partCurrentRow, 'inventory_money', subData.inventory_money);
                this.$set(this.partCurrentRow, 'available_num', subData.available_num);
              });
            } else if (this.activeName === '2') {
              this.$nextTick(() => {
                this.$set(this.mtrbCurrentRow, 'recording', subData.recording);
                this.$set(this.mtrbCurrentRow, 'inventory_money', subData.inventory_money);
                this.$set(this.mtrbCurrentRow, 'available_num', subData.available_num);
              });
            }
            this.$message.success('保存成功!');
            this.loadFlag = false;
          }
        );
      }
    },
    cancellation(row, index) {
      this.$confirm('确定作废?作废后库存不可用!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          row.recording_type = 3;
          row.status = 1;
          this.saveRow(row);
          this.$refs[this.activeName === '1' ? 'partMultiTable' : 'mtrbMultiTable'].clearSelection();
        })
        .catch(() => this.$message.info('已取消'));
    },
    minusNum(row, index) {
      this.$prompt('请输入数量', '减少数量', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^\d+$/,
        inputErrorMessage: '请输入正确的数量'
      }).then(({ value }) => {
        row[this.activeName === '1' ? 'prod_num' : 'mtrb_num'] -= Number(value);
        row.recording_type = 0;
        this.saveRow(row);
        row.recording_type = 5;
        this.$refs[this.activeName === '1' ? 'partMultiTable' : 'mtrbMultiTable'].clearSelection();
      });
    },
    plusNum(row, index) {
      this.$prompt('请输入数量', '减少数量', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^\d+$/,
        inputErrorMessage: '请输入正确的数量'
      }).then(({ value }) => {
        row[this.activeName === '1' ? 'prod_num' : 'mtrb_num'] += Number(value);
        row.recording_type = 1;
        this.saveRow(row);
        row.recording_type = 5;
        this.$refs[this.activeName === '1' ? 'partMultiTable' : 'mtrbMultiTable'].clearSelection();
      });
    },
    openRecording() {
      this.dialogVisible = true;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
