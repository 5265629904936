export const partTableProperties = [
  {
    label: '库存类型',
    prop: 'inventory_type',
    itemType: 'select',
    options: [
      { value: 0, label: '我司库存' },
      { value: 1, label: '客户库存' }
    ],
    input: true,
    sortable: false,
    labelWidth: 180,
    subItem: {
      disabled: true,
      type: 'newSelect',
      options: {
        0: { value: 0, label: '我司库存' },
        1: { value: 1, label: '客户库存' }
      }
    }
  },
  {
    label: '我司货号',
    prop: 'prod_no',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '客户货号',
    prop: 'prod_cust_no',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '部件名称',
    prop: 'prod_name',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '产品描述',
    prop: 'prod_desc',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      disabled: true,
      type: 'popoverInput'
    }
  },
  {
    label: '客户简称',
    prop: 'cust_abbr',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 180
  },
  { label: '客户订单号', prop: 'scon_cust_no', itemType: 'input', input: true, sortable: false, labelWidth: 180 },
  {
    label: '采购合同号',
    prop: 'podr_no',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '最终客户',
    prop: 'cust_babbr',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '部件单位',
    prop: 'prod_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  { label: '库存数量', prop: 'prod_num', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  { label: '冻结数量', prop: 'inventory_freezenum', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  { label: '采购单价', prop: 'prod_price', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  { label: '可用数量', prop: 'available_num', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  { label: '库存货值', prop: 'inventory_money', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  {
    label: '库存存储地',
    prop: 'supp_id',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      disabled: false,
      type: 'newSelect',
      options: {},
      filterable: true
    }
  },
  { label: '库存记录', prop: 'recording', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  {
    label: '是否我司结算',
    prop: 'settlement',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 120,
    subItem: {
      disabled: true,
      type: 'newSelect',
      options: {
        0: { value: 0, label: '是' },
        1: { value: 1, label: '否' }
      }
    }
  },
  {
    label: '备注',
    prop: 'remark',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      disabled: false,
      type: 'popoverInput'
    }
  },
  {
    label: '状态',
    prop: 'status',
    itemType: 'select',
    options: [
      { value: 0, label: '有效' },
      { value: 1, label: '作废' }
    ],
    input: true,
    labelWidth: 120
  },
  {
    label: '操作',
    prop: 'operation',
    itemType: 'input',
    input: false,
    fixed: 'right',
    labelWidth: 220
  }
];
export const mtrbTableProperties = [
  {
    label: '库存类型',
    prop: 'inventory_type',
    itemType: 'select',
    options: [
      { value: 0, label: '我司库存' },
      { value: 1, label: '客户库存' }
    ],
    input: true,
    sortable: false,
    labelWidth: 180,
    subItem: {
      disabled: true,
      type: 'newSelect',
      options: {
        0: { value: 0, label: '我司库存' },
        1: { value: 1, label: '客户库存' }
      }
    }
  },
  {
    label: '材料编号',
    prop: 'mtrb_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '材料类别',
    prop: 'mtrb_type',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      disabled: true,
      type: 'newSelect',
      options: {
        1: { value: 1, label: '辅料' },
        2: { value: 2, label: '包材' }
      }
    }
  },
  {
    label: '客户货号',
    prop: 'prod_cust_no',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '中文名称',
    prop: 'mtrb_name',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 180,
    subItem: {
      disabled: true,
      type: 'popoverInput'
    }
  },
  {
    label: '最终客户',
    prop: 'cust_babbr',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 180
  },
  { label: '客户订单号', prop: 'scon_cust_no', itemType: 'input', input: true, sortable: false, labelWidth: 180 },
  {
    label: '采购合同号',
    prop: 'modr_no',
    itemType: 'input',
    input: true,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '部件单位',
    prop: 'mtrb_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  { label: '库存数量', prop: 'mtrb_num', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  { label: '冻结数量', prop: 'inventory_freezenum', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  { label: '采购单价', prop: 'prod_mtrb_price', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  { label: '可用数量', prop: 'available_num', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  { label: '库存货值', prop: 'inventory_money', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  {
    label: '库存存储地',
    prop: 'supp_id',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      disabled: false,
      type: 'newSelect',
      options: {},
      filterable: true
    }
  },
  { label: '库存记录', prop: 'recording', itemType: 'input', input: false, sortable: false, labelWidth: 120 },
  {
    label: '是否我司结算',
    prop: 'settlement',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 120,
    subItem: {
      disabled: true,
      type: 'newSelect',
      options: {
        0: { value: 0, label: '是' },
        1: { value: 1, label: '否' }
      }
    }
  },
  {
    label: '备注',
    prop: 'remark',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      disabled: false,
      type: 'popoverInput'
    }
  },
  {
    label: '状态',
    prop: 'status',
    itemType: 'select',
    options: [
      { value: 0, label: '有效' },
      { value: 1, label: '作废' }
    ],
    input: true,
    labelWidth: 120
  },
  {
    label: '操作',
    prop: 'operation',
    itemType: 'input',
    input: false,
    fixed: 'right',
    labelWidth: 220
  }
];
