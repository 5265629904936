import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';

export const inventorysAPI = {
  getInventorys: params => getNoCatch(`${topic.inventorysTopic}/getInventory`, params),
  editInventoryPodr: params => postNoCatch(`${topic.inventorysTopic}/editInventoryPodr`, params),
  editInventoryMtrb: params => postNoCatch(`${topic.inventorysTopic}/editInventoryMtrb`, params),
  getInventoryProdById: `${topic.inventorysTopic}/getInventoryProdById`,
  getInventoryMtrbById: `${topic.inventorysTopic}/getInventoryMtrbById`
};
